import React, { useEffect, useRef } from 'react';
// import styled from 'styled-components';
import { styled } from '@mui/system';
import axios from 'axios';
import { Button, Box, Typography } from '@mui/material';
import PayPal from './PayPal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import PaymentAnimation from './PaymentAnimation';
import Loading from './Loading';
import '../css/GlobalCss.css';
import Stripe from './Stripe';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button)`
    min-width: 100%;
`;

const CustomButton = styled(Button)(({ theme }) => ({
    ...theme.typography.button,
    // padding: theme.spacing(1, 2),
    // borderRadius: theme.shape.borderRadius,
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main,
    // },
}));

export function Cta(props) {

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    return (

        <CtaBox
            sx={{
                justifyContent: md ? 'flexStart' : 'center'
            }}
        >
            {   props.state ?
                <CustomButton 
                    variant="contained" 
                    className="btn btn-success" 
                    onClick={() => props.openPayment()}
                    size="sm"
                    sx={{
                        width: md ? 'auto' : '100%'
                    }}
                >
                    {props.cartContent['go-to-payment-button'][`${props.language}`]}
                </CustomButton>
                :
                <Button
                    variant="contained" 
                    className="btn btn-success" 
                    onClick={() => props.openPayment()}
                    size="large"
                    sx={{
                        width: md ? 'auto' : '100%'
                    }}
                    disabled
                >
                    {props.cartContent['go-to-payment-button'][`${props.language}`]}
                </Button>
            }
        </CtaBox>

    )
}

const PaymentButtonsBox = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    margin-top: 16px;
    min-width: 100%;
`;

const ActionBox = styled(Box)`
    display: flex;
    justify-content: center;
`;

const AnimationBox = styled(Box)`
    display: flex;
    justify-content: center;
    ${'' /* max-width: 320px; */}
`;

const CtaBox = styled(Box)`
    display: flex;
    ${'' /* justify-content: center; */}
    padding: 0 16px 0 16px;
`;

const LoadingBox = styled(Box)`
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;
`;

class Create extends React.Component {
    constructor(props) {
        super(props);
    }
    
    state = {
        payment: false,
        paypalMount: false,
        stripeMount: false,
        payAtDesk: false,
        loading: false,
        tse: String
    }

    handleSubmit = (orderID) => {

        this.setState( prevState => (
            {
                prevState,
                loading: true
            }
        ))

        this.handOffOrder('paypal', orderID);
    }

    handleSubmitStripe = (orderID) => {

        this.setState( prevState => (
            {
                prevState,
                loading: true
            }
        ))

        this.handOffOrder('stripe', orderID);
    }

    openPayment = () => {

        this.setState( prevState => (
            {
                payment: true
            }
        ))
    }

    closePayment = () => {

        this.setState( prevState => (
            {
                payment: false,
                payAtDesk: false
            }
        ))
    }

    handleCheckMount = (input) => {
        this.setState( prevState => (
            {
                ...prevState,
                paypalMount: input
            }
        ))
    }

    handleCheckStripeMount = (input) => {
        this.setState( prevState => (
            {
                ...prevState,
                stripeMount: input
            }
        ))
    }

    makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    }

    payAtDesk = () => {

        this.setState( prevState => (
            {
                payAtDesk: true
            }
        ))
        // e.preventDefault();
    }
    handlePayAtDeskCash = () => {

        const orderID = this.makeid(5);

        this.setState( prevState => (
            {
                prevState,
                loading: true
            }
        ))

        this.handOffOrder('Cash', orderID);
    }
    handlePayAtDeskCard = async () => {

        const orderID = this.makeid(5);

        this.setState( prevState => (
            {
                prevState,
                loading: true
            }
        ))

        this.handOffOrder('Card', orderID);
    }
    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }
    handOffOrder = (payment, orderID) => {

        const order = {
            props: this.props,
            id: orderID,
            payment: payment
        };

        if(this.props.purchases.length !== 0) {
            axios
            .post(`/create/${this.props.store}`, order)
            .then( async (res) => {
                if(res.data.status === 'success'){

                    if(this.props.cashier){

                    }

                    await this.timeout(2000);
                    this.props.onCheckout(orderID);
                    // setTimeout(() => {
                    //     this.props.onCheckout(orderID)
                    // }, 2000)
                }
            })
            .catch(err => {
                console.error(err);
            });
        } else {
            console.log('no input')
        }
        // e.preventDefault();
    }
    
    render() {

        return (
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Cta language={this.props.language} cartContent={this.props.cartContent} openPayment={this.openPayment} state={this.props.purchases.length !== 0 ? true : false} />
                {
                    this.state.payment && this.props.purchases.length !== 0 &&
                    <Dialog
                        sx={{
                            margin: 4,
                            '& .MuiPaper-root': {
                                width: '100%',
                            },
                        }}
                        open={this.state.payment}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.closePayment}
                        aria-describedby="alert-dialog-slide-description"

                    >
                        <DialogTitle
                            sx={{ 
                                textAlign: 'center', 
                            }}
                        >
                            {   this.state.payAtDesk ?
                                this.props.cartContent['pay-at-desk-method'][`${this.props.language}`]
                                :
                                this.props.cartContent['payment-title'][`${this.props.language}`]
                            }
                        </DialogTitle>
                        {   this.state.payAtDesk ?
                            <DialogContent>
                                {/* pay at desk dialog */}
                                {   this.state.loading ?
                                    <LoadingBox>
                                        <Loading />
                                    </LoadingBox>
                                    :
                                    <ActionBox>
                                        <DialogActions> 
                                            <Button className="btn btn-success" onClick={this.handlePayAtDeskCash}>
                                                {this.props.cartContent['pay-at-desk-cash'][`${this.props.language}`]}
                                            </Button>
                                            <Typography variant="body2" color="text.secondary">
                                                {this.props.cartContent['or'][`${this.props.language}`]}
                                            </Typography>
                                            <Button className="btn btn-success" onClick={this.handlePayAtDeskCard}>
                                                {this.props.cartContent['pay-at-desk-card'][`${this.props.language}`]}
                                            </Button>
                                        </DialogActions>
                                    </ActionBox>
                                }
                            </DialogContent>
                            :
                            <DialogContent
                                sx={{

                                }}
                            >
                                {/* payment selection */}
                                <AnimationBox>
                                    <PaymentAnimation width='140px' height='140px' />
                                </AnimationBox>
                                <DialogContent
                                    sx={{
                                        p: 0,
                                        width: '100%',
                                    }}
                                >
                                    <DialogActions 
                                        sx={{ 
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            minWidth: '100%' 
                                        }}
                                    > 
                                        {   this.props.cashier ?
                                            this.props.settings['cashier-pay-at-desk'] && this.props.settings['cashier-pay-at-desk'].checked &&
                                            <StyledButton variant="contained" sx={{ minWidth: '100%' }} className="btn btn-success" onClick={this.payAtDesk} sx={{ m: 1 }}>
                                                {this.props.cartContent['pay-at-desk-button'][`${this.props.language}`]}
                                            </StyledButton>
                                            :
                                            this.props.settings['client-pay-at-desk'] && this.props.settings['client-pay-at-desk'].checked ?
                                            <StyledButton variant="contained" sx={{ minWidth: '100%' }} className="btn btn-success" onClick={this.payAtDesk} sx={{ m: 1 }}>
                                                {this.props.cartContent['pay-at-desk-button'][`${this.props.language}`]}
                                            </StyledButton>
                                            :
                                            <></>
                                        }
                                        {   this.props.cashier ?
                                            this.props.settings['cashier-paypal'].checked || this.props.settings['cashier-stripe'].checked &&
                                            <PaymentButtonsBox>
                                                {   this.props.settings['cashier-stripe'].checked && 
                                                    <Stripe language={this.props.language} store={this.props.store} purchases={this.props.purchases} cartContent={this.props.cartContent} handlePushPaymentToBacklog={(idInput) => this.handleSubmitStripe(idInput)} />
                                                }
                                                {   this.props.settings['cashier-paypal'].checked &&
                                                    <PayPal onCheckMount={(input) => this.handleCheckMount(input)} store={this.props.store} clientId={this.props.paypalId} purchases={this.props.purchases} onSuccessfulPayment={this.handleSubmit} />
                                                }
                                            </PaymentButtonsBox>
                                            :
                                            this.props.settings['client-paypal'].checked || this.props.settings['client-stripe'].checked ?
                                            <PaymentButtonsBox>
                                                {   this.props.settings['client-stripe'].checked && 
                                                    <Stripe language={this.props.language} store={this.props.store} purchases={this.props.purchases} cartContent={this.props.cartContent} handlePushPaymentToBacklog={(idInput) => this.handleSubmitStripe(idInput)} />
                                                }
                                                {   this.props.settings['client-paypal'].checked &&
                                                    <PayPal onCheckMount={(input) => this.handleCheckMount(input)} store={this.props.store} clientId={this.props.paypalId} purchases={this.props.purchases} onSuccessfulPayment={this.handleSubmit} />
                                                }
                                            </PaymentButtonsBox>
                                            :
                                            <></>
                                        }
                                    </DialogActions>
                                </DialogContent>
                            </DialogContent>
                        }
                    </Dialog>
                }
            </Box>
        )
    }
}

export default Create;

