import React, { useState } from 'react';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { theme_light } from './components/theme_light';
import { theme_dark } from './components/theme_dark';
import { ThemeProvider } from '@mui/material/styles';
import Invoice from './components/Invoice';
import LanguagePage from './components/LanguagePage';
import MapLanding from './components/MapLanding';
import FooterMap from './components/FooterMap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import StripeRedirect from './components/StripeRedirect';
import StoreMain from './components/StoreMain';
import ProductPage from './components/ProductPage';
import ZReport from './components/ZReport';
import Page from './components/Page';

library.add(fab, faCoffee)

function App() {

  const [simpleLayout, setSimpleLayout] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [primaryColor, setPrimaryColor] = useState();

  const [idCounts, setIdCounts] = useState(0);
  const [purchases, setPurchases] = useState([]);
  const [total, setTotal] = useState(0);

  const [savedNote, setSavedNote] = useState();

  function handleUpdateCart(item) {
    let purchasesMemory = purchases;
    //add item
    const newCount = idCounts + 1;
    setIdCounts(newCount);
    item.id = newCount;
    purchasesMemory.push(item);
    setPurchases(purchasesMemory);
    //calculate total
    calculateTotal(purchasesMemory);
  }

  function handleChangeQuantity(action, inputItem) {
    let purchasesMemory = purchases;
    //plus
    if(action === 'plus') {
      setPurchases(prevValue => {
        return prevValue?.map((el, index) => {
          if(el.id === inputItem.id){
            purchasesMemory[index]['quantity'] = purchasesMemory[index]['quantity'] + 1;

            return purchasesMemory[index];
          }
          return purchasesMemory[index];
        })
      })
    }
    //minus
    if(action === 'minus'){

      let deleteItem = false;

      purchasesMemory.forEach((el) => {
        if(el.id === inputItem.id){
          if(el['quantity'] === 1){
            deleteItem = true;  
          }
        }
      })

      if(deleteItem){
        setPurchases(prevValue => {
          return prevValue.filter((item) => {
            return item.id !== inputItem.id;
          })
        })
        purchasesMemory = purchasesMemory.filter((item) => {
          return item.id !== inputItem.id;
        })
      } else {
        setPurchases(prevValue => {
          return prevValue?.map((el, index) => {
            if(el.id === inputItem.id){
              purchasesMemory[index]['quantity'] = purchasesMemory[index]['quantity'] - 1;

              return purchasesMemory[index];
            }
            return purchasesMemory[index];
          })
        })
      }
    }

    calculateTotal(purchasesMemory);
  }

  function calculateTotal (inputPurchases) {
    let currTotal = inputPurchases.reduce((sum, item) => {
        // console.log(item)
        return sum + ( ( Number(item['total-price']) - Number(item['discounted-price']) ) * Number(item['quantity']) );
        // * item.quantity
    }, 0)
    currTotal = Number.parseFloat(currTotal).toFixed(2);
    currTotal = Number(currTotal);
    setTotal(currTotal);
  }

  function cleanUpCart(){
    setPurchases([]);
  }

  function handlePassNote(noteInput){
    setSavedNote(noteInput)
  }

  function handleUpdateLayout(input) {
    setSimpleLayout(input)
  }

  function handleUpdateColorMode(inputMode, inputPrimaryColor) {
    setDarkMode(inputMode);
    if(inputPrimaryColor !== 'bypass'){
      theme_dark.palette.primary.main = inputPrimaryColor;
      theme_light.palette.primary.main = inputPrimaryColor;
    }
  }

  React.useEffect(() => {
    


  },[])
  
  return (
    <Router>
      <ThemeProvider theme={darkMode ? theme_dark : theme_light}>
        <Switch>
          {/* Map */}
          {/* map */}
          {/* <Redirect from="/map/:mapType/:language/" to="/map/:mapType/:language/" /> */}
          <Route exact path='/map/:mapTypeId/:language/'>
            <MapLanding />
            <FooterMap />
          </Route>

          {/* target the link I put in cv */}
          <Route exact path='/g4PotzdamerPlatz/en'>
            <Redirect to="/main/g4PotzdamerPlatz/en" />
          </Route>

          {/* language gateway */}
          <Route exact path='/language/:store'>
            <LanguagePage />
          </Route>

          {/* process stripe payment */}
          <Route exact path='/process-payment/:store/:language/:stripeKey'>
            <StripeRedirect />
          </Route>

          {/* language gateway */}
          <Route exact path='/page/:page/:store/:language'>
            <Page />
          </Route>

          {/* cashier invoice */}
          <Route path='/cashier/:store/:language/invoice/:orderID'>
            <Invoice cashier />
            <Footer cashier={true} />
          </Route>
          {/* print z-report */}
          <Route path='/cashier/:store/:language/z-report/:reportId'>
            <ZReport />
            <Footer cashier={true} />
          </Route>
          {/* cashier */}
          <Route exact path='/cashier/:store/:language'>
            <StoreMain 
              cashier
              darkMode={darkMode}
              simpleLayout={simpleLayout}
              purchases={purchases} 
              total={total} 
              savedNote={savedNote && savedNote}
              catchUpdateCart={(item) => handleUpdateCart(item)} 
              onCleanUpCart={() => cleanUpCart()} 
              passNote={(noteRecord) => handlePassNote(noteRecord)}
              onHandleChangeQuantity={(action, inputItem) => handleChangeQuantity(action, inputItem)} 
              onUpdateLayout={(input) => handleUpdateLayout(input)}
              onUpdateColorMode={(inputMode, inputPrimaryColor) => handleUpdateColorMode(inputMode, inputPrimaryColor)}
            />
            <Footer scrollButtons cashier={true} />
          </Route>

          <Route path='/main/:store/:language'>
              <StoreMain 
                darkMode={darkMode}
                simpleLayout={simpleLayout}
                purchases={purchases} 
                total={total} 
                savedNote={savedNote && savedNote}
                onCleanUpCart={() => cleanUpCart()} 
                passNote={(noteRecord) => handlePassNote(noteRecord)}
                onHandleChangeQuantity={(action, inputItem) => handleChangeQuantity(action, inputItem)} 
                onUpdateLayout={(input) => handleUpdateLayout(input)}
                onUpdateColorMode={(inputMode, inputPrimaryColor) => handleUpdateColorMode(inputMode, inputPrimaryColor)}
              />
              <Footer cashier={false} />
          </Route>

          <Route path='/product/:store/:language/:product'>
            <ProductPage
              client 
              onUpdateCart={(item) => handleUpdateCart(item)}   
              onUpdateColorMode={(inputMode, inputPrimaryColor) => handleUpdateColorMode(inputMode, inputPrimaryColor)}
            />
          </Route>

          <Route path='/:store/:language/invoice/:orderID'>
            <Invoice client />
            <Footer cashier={false} />
          </Route>

          <Route path='/'>
            <Redirect to="/map/recrnq4r5tmTPXuhN/en" />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );

}

export default App;
