import React from 'react';
import { styled } from '@mui/material/styles';
import useFetch from './useFetch';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import { useTheme } from '@mui/material';

const SC_Card = styled(Card)(({theme}) => ({
    margin: '4px 0',
    borderRadius: '10px !important',
    position: 'relative'
}));

const Sc_Box = styled(Box)(({theme}) => ({
    padding: '8px',
}));

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)(({theme}) => ({
    color: 'white !important'
}));

const Sc_CardMedia = styled(CardMedia)(({theme}) => ({
    borderRadius: '10px',
}));

const Sc_CardContent = styled(CardMedia)(({theme}) => ({
    padding: '8px',
}));

export default function SimpleCardButton (props){

    const theme = useTheme();

    const handleClick = React.useCallback(() => {

        if(!props.selected){
            props.onClick(props.data, 'plus');
        } else {
            props.onClick(props.data, 'minus');
        }
    }); 

    function renderCard(input){
        return (
            <Sc_Box 
                sx={{ 
                    alignItems: 'flex-start', 
                    display: 'flex', 
                    position: 'relative', 
                    width: '100%', 
                    height: '100%',
                }} 
            >
                {   props.image &&
                    <Sc_CardMedia
                        component="img"
                        sx={{ width: 60, height: 60 }}
                        image={props.image}
                        alt={props.title}
                    />
                }
                <Sc_CardContent 
                    sx={{ 
                        color: `${props.selected ? theme.palette.text.on_surface_inverse : 'unset' }` 
                    }}
                >
                    <Typography variant="h6" 
                        color={props.selected ? theme.palette.text.on_surface_reverse : theme.palette.text.on_surface}
                    >
                        {props.title && props.title}
                    </Typography>
                    {   props.text &&
                        <Typography variant="body2" 
                            color={props.selected ? theme.palette.text.on_surface_reverse : theme.palette.text.on_surface_variant}
                        >
                            {props.text}
                        </Typography>
                    }
                </Sc_CardContent>
            </Sc_Box>
        )
    }

    return(

        <SC_Card 
            style={{
                backgroundColor: props.selected ? theme.palette.primary.main : theme.palette.secondary.main, 
            }} 
            elevation={ props.selected ? 0 : 5 }
            sx={{ 
                maxWidth: 1440, 
                display: 'flex', 
                flexDirection: 'row', 
                flexGrow: 1,
                width: {sm: '100%'},
                maxWidth: {xl: '720px'},
            }}
        >
            {   props.active ?
                <CardActionArea onClick={() => handleClick()}>
                    {renderCard()}
                </CardActionArea>
                :
                renderCard()
            }
            {   !props.active && props.warning &&
                <OverlayBox>
                    <TextBox>
                        <Typography variant="body1" color="text.secondary" sx={{ color: 'white' }}>
                            {
                                props.warning
                            }
                        </Typography>
                    </TextBox>
                </OverlayBox>
            }
        </SC_Card>

    )

}