import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import { theme_except_palette } from './theme_except_palette';

const darkPalette = {
    mode: 'dark',
    primary: {
        main: '#ffffff',
        background: '#000000',
    },
    secondary: {
        main: '#2E2E2E',
    },
    neutral: {
        main: '#f0f0f0',
    },
    danger: {
        main: '#f44336',
    },
    black: {
        main: '#000000',
        o_eightyseven: 'rgba(0, 0, 0, 0.87)',
    },
    white: {
        main: '#ffffff',
    },
    blue_lighten_4: {
        main: 'rgba(187, 222, 251, 1)',
    },
    grey_lighten_2: {
        main: '#E0E0E0',
    },
    grey_lighten_5: {
        main: '#FAFAFA'
    },
    grey: {
        lighten_1: '#BDBDBD',
        lighten_4: '#F5F5F5',
        lighten_5: '#FAFAFA',
        darken_3: '#424242'
    },
    background: {
        paper: '#242424',
    },
    text: {
        on_surface: '#ffffff',
        on_surface_variant: 'rgba(255, 255, 255, 0.5)',
        on_surface_inverse: '#000000',
        on_surface_variant_inverse: 'rgba(0, 0, 0, 0.5)',
    },
    shadow: {
        button: '0px 4px 2px rgba(0, 0, 0, 0.6)',
    },
}

export const theme_dark = responsiveFontSizes(createTheme({
    palette: darkPalette,
    typography: theme_except_palette.typography,
    spacing: theme_except_palette.spacing,
    breakpoints: theme_except_palette.breakpoints,
}));