import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Stack, Box, CardActionArea, Typography, Divider } from '@mui/material';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Div = styled(Box)`
    display: flex;
`;

const Sc_Card = styled(Card)`
    border-radius: 100%
    width: 32px
`;

const ButtonsBox = styled(ButtonGroup)`
    display: flex;
    min-width: 100% !important;
`;

const ScGrid = styled(Grid)`
    min-width: 100%;
    width: 100%;
`;


const IconBox = styled(Box)`
    align-items: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    line-height: unset !important;
    min-width: 40px;
    min-height: 40px;
`;

const Sc_CardActionArea = styled(CardActionArea)`
    align-items: center;
    display: flex !important;
    min-height: 48px;
    justify-content: flex-start;
    flex-grow: 1;
`;



export default function IconButtonsGroup ({ content, language, product, helperText, buttons, onClick }){

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const [helperInfo, setHelperInfo] = React.useState(helperText);

    const [currValue, setCurrValue] = React.useState();

    const updateValue = async (input) => {

        const {name, value} = input;
        setCurrValue(value);
        setHelperInfo('');
        value && onClick(value);
    };

    return(

        <Box
            sx={{
                display: 'flex',
                minWidth: '100%',
                '& > *': {
                m: 1,
                },
            }}
        >
            <ButtonsBox
                variant="outlined" 
                aria-label="outlined button group"
            >
                <ScGrid 
                    container 
                    spacing={2}
                >
                    {   
                        buttons && buttons?.map((el, index) => {

                            const iconDefault = theme.palette.mode === 'dark' ? el['icon_reverse'] : el.icon;
                            const iconSelected = theme.palette.mode === 'dark' ? el.icon : el['icon_reverse'];

                            return (
                                <Grid
                                    key={index}
                                    xs={xl ? 2 : md ? 3 : sm ? 6 : xs ? 12 : 12}
                                >
                                    <Card
                                        sx={{
                                            backgroundColor: `${el.value === currValue ? `${theme.palette.primary.main} !important` : theme.palette.secondary.main}`,
                                        }}
                                    >
                                        <Sc_CardActionArea 
                                            onClick={() => updateValue({name: el.name, value: el.value})}
                                        >
                                            <Stack
                                                key={index}
                                                direction='row'
                                                spacing={2}
                                                sx={{
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <IconBox>
                                                    {/* default */}
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ 
                                                            width: 24, 
                                                            height: 24, 
                                                            objectFit: 'cover', 
                                                            display:  `${el.value === currValue ? 'none' : 'block'}`
                                                        }}
                                                        image={iconDefault}
                                                        alt='icon'
                                                    />
                                                    {/* inverse */}
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ 
                                                            width: 24, 
                                                            height: 24, 
                                                            objectFit: 'cover', 
                                                            display:  `${el.value === currValue ? 'block' : 'none'}`
                                                        }}
                                                        image={iconSelected}
                                                        alt='icon'
                                                    />
                                                </IconBox>
                                                <Typography 
                                                    variant="body1" 
                                                    sx={{
                                                        textAlign: 'left',
                                                        color: `${el.value === currValue ? `${theme.palette.text.on_surface_inverse} !important` : theme.palette.text.on_surface}`,
                                                    }}
                                                >
                                                    {el.label}
                                                </Typography>
                                            </Stack>
                                        </Sc_CardActionArea>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </ScGrid>
            </ButtonsBox>
        </Box>

    )

}