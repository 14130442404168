import React, {useState} from 'react';
import NumberDial from './NumberDial';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const NumberDialBox = styled(Box)(({theme}) => ({
    display: 'flex',
    textAlign: 'center'
}));

const ScCard = styled(Card)(({theme}) => ({
    borderRadius: '10px !important',
    display: 'flex',
    textAlign: 'center',
    flexGrow: 1,
    maxWidth: '1440px',
    overflow: 'visible !important'
}));

const ScStack = styled(Stack)(({theme}) => ({
    padding: '8px',
}));

const OverlayBox = styled(Box)`
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 99;
`;

const TextBox = styled(Box)(({theme}) => ({
    color: 'white !important',
}));

const Sc_CardContent = styled(CardContent)(({theme}) => ({
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 8px',
    padding: '0px !important',
}));

export default function CardButtonWithCounter (props){

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [currValue, setCurrValue] = useState(0);

    function handleClick() {
        handleChange('plus')
    }

    function handleChange(action){

        let selectedAction = action;

        let recordMemory = props.record;

        let valueMemory = currValue;
        
        if(selectedAction === 'minus'){
            if(valueMemory > 1) {
                valueMemory = valueMemory - 1;
                props.onChange('minus', recordMemory);
            } else if(valueMemory === 1){
                valueMemory = 0;
                props.onChange('minus', recordMemory);
            } else {

            }
            setCurrValue(valueMemory);
        } else if (selectedAction === 'plus') {
            if( Number(props.limit) >= 1) {
                valueMemory = valueMemory + 1;
                setCurrValue(valueMemory);
                props.onChange('plus', recordMemory);
            } else {
                let alertMessage = 'exceed limit';
                props.onAlert(alertMessage);
            } 
        }
    }

    const handleAlert = React.useCallback((inputAlert) => {
        props.onAlert(inputAlert)
    })

    function renderCardBody(){
        return (
            <ScStack
                direction={{xs: 'column', sm: 'row'}}
                sx={{ 

                }}
            >
                {
                    props.record.fields.image &&
                    <CardMedia
                        component="img"
                        sx={{ 
                            maxWidth: sm ? 60 : 100 , 
                            height: sm ? 60 : 100, 
                            flexGrow: 1, 
                            objectFit: 'cover', 
                            borderRadius: '10px' 
                        }}
                        image={props.image}
                        alt={props.title}
                    />
                }
                <Sc_CardContent
                    sx={{ flexGrow: 1 }}
                >
                    <Typography align='left' variant="h5" color={theme.palette.text.on_surface}>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color={theme.palette.text.on_surface_variant}>
                        {props.text}
                    </Typography>
                </Sc_CardContent>
            </ScStack>
        )
    }

    

    React.useEffect(() => {

    },[currValue])

    return(

        <ScCard 
            sx={{
                border: currValue > 0 ? `3px solid ${theme.palette.primary.main}` : 'none', 
                position: 'relative',
            }}
            elevation={ currValue > 0 ? 0 : 5 }
        >
            <Stack
                direction={{sm: 'row', lg: 'column'}}
                sx={{
                    minWidth: '100%',
                }}
            >
                <CardActionArea
                    sx={{ 
                        width: '100%', 
                        height: '100%', 
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start'
                    }}
                    onClick={() => handleClick()}
                    disabled={currValue === 0 ? false : true}
                >
                    {renderCardBody()}
                </CardActionArea>
                {   props.disabled &&
                    <OverlayBox>
                        <TextBox>
                            <Typography variant="body1" color={theme.palette.white.main}>
                                {
                                    props.disabledMessage
                                }
                            </Typography>
                        </TextBox>
                    </OverlayBox>
                }
                {   currValue > 0 &&
                    <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            minWidth: {
                                xs: '100%',
                                sm: 'unset'
                            }
                        }}
                    >
                        <NumberDialBox
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                            }}
                        >
                            <NumberDial record={props.record} limit={props.limit} initialValue={currValue} onChange={(action) => handleChange(action)} onAlert={(alertMessage) => handleAlert(alertMessage)} />
                        </NumberDialBox>
                    </Box>
                }
            </Stack>
        </ScCard>

    )

}