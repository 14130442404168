import React, { useCallback, useEffect, useState }  from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';
import { useParams } from 'react-router'
import { getThemeProps } from '@mui/system';
import textLogo from '../textLogo_v1.svg';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { theme } from './theme_light'
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { device } from './device';
import CardMedia from '@mui/material/CardMedia';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Image = styled.img`
    height: 32px;
    @media ${device.mobileM}{
        height: 40px;
    }
`;

const StyledDivider = styled(Divider)`
    margin: 0 0 16px 0;
`;

const Sc_CardMedia = styled(CardMedia)`
    height: auto;
    width: 100%;
    @media ${device.MobileL}{
        max-width: 120px;
        height: 100%;
    }
`;

// const pages = ['Contact', 'About'];
// const settings = [ 'Deutsch', '中文', 'English'];

const content = {}

function Footer_Store (props) {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));

    const [url_footer, setUrl_footer] = useState('/root/footer');

    const {store, language} = useParams();

    const [isFetching, setIsFetching] = useState(false);
    const fetchData_footer = useCallback(() => {

        fetch(url_footer)
            .then(response => {
            if (!response.ok) {
                throw new Error(`status ${response.status}`);
            }
            return response.json();
            })
            .then(json => {
            json?.map(el => {

                const contentItem = {
                    'en': String,
                    'ch': String,
                    'de': String,
                    'image': Array
                }
    
                contentItem.en = el.fields.en;
                contentItem.ch = el.fields.ch;
                contentItem.de = el.fields.de;
                contentItem.image = el.fields.image;
    
                content[el.fields.name] = contentItem;
            });
            setIsFetching(false);
            }).catch(e => {
            console.log('root content call failed')
            setIsFetching(false);
            })

    }, [url_footer]);

    function handleModewitch (event) {
        props.onUpdateColorMode(event.target.checked, 'bypass');
    }

    useEffect(() => {

        let mounted = true;
        if(mounted) {
            setIsFetching(true);
            fetchData_footer();
        }
        return () => {
            mounted = false;
        }
    }, [fetchData_footer]);

    return(
        <Container sx={{paddingTop: 8, paddingBottom: 1, minWidth: '100%'}}>
            <Stack>
                {   props.language && props.storeMedia['logo'] &&
                    <Stack
                        sx={{ 
                            flexGrow: 1 , 
                            mt: 1,
                        }} 
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                gap: 2,
                            }}
                        >
                            <Box sx={{ display: { xs: 'block', md: 'flex' }, justifyContent: 'space-between' }} >
                                <Image src={props.storeMedia['logo'].attachments[0].thumbnails.small.url} />
                            </Box>
                            <Box>
                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontWeight: 700,
                                    }}
                                    color={theme.palette.text.on_surface}
                                >
                                    {   
                                        props.storeContent['restaurant-name'] && props.storeContent['restaurant-name'][`${props.language}`]
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    </Stack>
                }

                {   props.rootContent && props.storeContent && props.language &&
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: { 
                                xs: 'column', md: 'row' 
                            }, 
                            paddingTop: 4,
                            paddingBottom: 2,
                            mb: 2 
                        }} 
                    >
                        {/* Store info box */}
                        <Stack
                            spacing={{
                                xs: 4,
                                sm: 12,
                            }}
                            direction={{
                                xs: 'column',
                                sm: 'row',
                            }}
                        >
                            {/* contact box */}
                            <Stack spacing={2}>
                                {/* address box */}
                                <Stack>
                                    <Typography variant='caption'
                                        color={theme.palette.text.on_surface}
                                    >
                                        {
                                            props.rootContent["restaurant-address"] &&
                                            props.rootContent["restaurant-address"]
                                            [`${props.language}`]
                                        }
                                    </Typography>
                                    <Typography variant='body1'
                                        color={theme.palette.text.on_surface}
                                    >
                                        {
                                            props.storeContent["restaurant-address"] &&
                                            props.storeContent["restaurant-address"]
                                            [`${props.language}`]
                                        }
                                    </Typography>
                                </Stack>
                                {/* telephone box */}
                                <Stack>
                                    <Typography variant='caption'
                                        color={theme.palette.text.on_surface}
                                    >
                                        {
                                            props.rootContent["restaurant-telephone"] &&
                                            props.rootContent["restaurant-telephone"]
                                            [`${props.language}`]
                                        }
                                    </Typography>
                                    <Typography variant='body1'
                                        color={theme.palette.text.on_surface}
                                    >
                                        {
                                            props.storeContent["restaurant-telephone"] &&
                                            props.storeContent["restaurant-telephone"]
                                            [`${props.language}`]
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                            {/* Business hours box */}
                            <Stack>
                                <Typography variant='caption'
                                    color={theme.palette.text.on_surface}
                                >
                                    {
                                        props.rootContent["open-hours"] &&
                                        props.rootContent["open-hours"][`${props.language}`]
                                    }
                                    <Stack spacing={2}>
                                        {/* monday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["monday"] &&
                                                    props.rootContent["monday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-1"] &&
                                                    props.storeSettings["open-day-1"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-1"] &&
                                                    props.storeSettings["close-day-1"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Tuesday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["tuesday"] &&
                                                    props.rootContent["tuesday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-2"] &&
                                                    props.storeSettings["open-day-2"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-2"] &&
                                                    props.storeSettings["close-day-2"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Wednesday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["wednesday"] &&
                                                    props.rootContent["wednesday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-3"] &&
                                                    props.storeSettings["open-day-3"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-3"] &&
                                                    props.storeSettings["close-day-3"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Thursday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["thursday"] &&
                                                    props.rootContent["thursday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-4"] &&
                                                    props.storeSettings["open-day-4"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-4"] &&
                                                    props.storeSettings["close-day-4"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Friday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["friday"] &&
                                                    props.rootContent["friday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-5"] &&
                                                    props.storeSettings["open-day-5"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-5"] &&
                                                    props.storeSettings["close-day-5"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Saturday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["saturday"] &&
                                                    props.rootContent["saturday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-6"] &&
                                                    props.storeSettings["open-day-6"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-6"] &&
                                                    props.storeSettings["close-day-6"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                        {/* Sunday */}
                                        <Stack>
                                            <Typography variant='caption'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {
                                                    props.rootContent["sunday"] &&
                                                    props.rootContent["sunday"]
                                                    [`${props.language}`]
                                                }
                                            </Typography>
                                            <Typography variant='body1'
                                                color={theme.palette.text.on_surface}
                                            >
                                                {   props.storeSettings["open-day-0"] &&
                                                    props.storeSettings["open-day-0"].time / 3600
                                                }
                                                -
                                                {   props.storeSettings["close-day-0"] &&
                                                    props.storeSettings["close-day-0"].time / 3600
                                                }
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                }
            </Stack>
            {/* color mode */}
            <Stack>
                <Stack>
                    <Typography variant='caption'
                        color={theme.palette.text.on_surface}
                    >
                        {
                            content["dark-mode"] &&
                            content["dark-mode"]
                            [`${props.language}`]
                        }
                    </Typography>
                    {   props.storeSettings && props.storeSettings['dark-mode-button'] && props.storeSettings['dark-mode-button'].checked &&
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch 
                                        sx={{ m: 1 }} 
                                        checked={props.darkMode ? props.darkMode : false}
                                        onChange={handleModewitch}
                                    />
                                }
                                // label={content && content['dark-mode'] && content['dark-mode'][`${language}`]}
                            />
                        </Box>
                    }
                </Stack>
            </Stack>
        </Container>
    );
}

export default Footer_Store;