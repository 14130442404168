import React, {useState, useLayoutEffect} from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useFetch from './useFetch';
import ImageSlides from './ImageSlides';
import Footer from './Footer';
import { useParams } from 'react-router';
import {useHistory, useLocation} from 'react-router-dom';
import SimpleButtonsGroup from './SimpleButtonsGroup';
import IconButtonsGroup from './IconButtonsGroup';
import CustomizationSection from './CustomizationSection';
import ExtraSection from './ExtraSection';
import Size from './Size';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import readAirtableData from '../hooks/readAirtableData';
import Fab from '@mui/material/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMinus } from '@fortawesome/free-solid-svg-icons';
import DiscountSection from './DiscountSection';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from "@mui/material/useMediaQuery";

const ContainerStack = styled(Stack)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: '100%',
    // overflowX: 'hidden',
}));

const ScFab = styled(Fab)(({theme}) => ({
    gap: theme.spacing(2),
}));

const MainStack = styled(Stack)(({theme, cashier}) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '16px 16px',
    position: 'relative',
}));


const ButtonsGroupBox = styled(Box)(({theme}) => ({
    borderRadius: '10px',
    display: 'flex',
    margin: '4px 0',
}));


const BackButtonDiv = styled(Box)(({theme}) => ({
    display: 'inline',
    left: '20px',
    position: 'fixed',
    top: '20px',
    textAlign: 'left',
    zIndex: 999
}));

const ImageHolder = styled(Box)(({theme}) => ({
    position: 'fixed',
    width: '100%'
}));

const DragLineHolder = styled(Box)(({theme}) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
}));

const allSugar = [
    {
        label: '0-sugar',
        value: 0
    },
    {
        label: '1-sugar',
        value: 1
    },
    {
        label: '3-sugar',
        value: 3
    },
    {
        label: '5-sugar',
        value: 5
    },
    {
        label: '7-sugar',
        value: 7
    },
    {
        label: '10-sugar',
        value: 10
    },
];
const allTemperatures = [
    {
        label: 'no-ice',
        value: 'no-ice'
    },
    {
        label: 'less-ice',
        value: 'less-ice'
    },
    {
        label: 'normal',
        value: 'normal'
    },
    {
        label: 'more',
        value: 'more'
    },
    {
        label: 'warm',
        value: 'warm'
    },
];

function ProductPage(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const [rootContent, setRootContent] = useState(props.cashier ? props.rootContent : null);
    const [storeSettings, setStoreSettings] = useState(props.cashier ? props.settings : null);


    const [productItem, setProductItem] = useState(props.cashier ? props.productItem : null);

    const [sugar, setSugar] = useState();
    const [temperature, setTemperature] = useState();

    const location = useLocation();

    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState();

    const [mount,setMount] = useState(false);
    const [loadProduct, setLoadProduct] = useState(false);

    const [item, setItem] = useState({
        'id': Number,
        'product': {},
        'size': null,
        'sugar': 'NaN',
        'sugar-name': 'NaN',
        'temperature': 'NaN',
        'temperature-name': 'NaN',
        'toppingIds': [],
        'toppings': [],
        'customization': {},
        'extra-price': 0,
        'customization-price': 0,
        'cup-price': 0,
        'total-price': 0,
        'payment': '',
        'discount-type': '',
        'discount-value': 0,
        'discounted-price': 0,
        'discounted-total-price': 0,
        'discount': null,
        'post-from': props.cashier ? 'cashier' : 'client',
        'quantity': 1
    });

    const itemMemory = {
        'id': Number,
        'product': {},
        'size': null,
        'sugar': 'NaN',
        'sugar-name': 'NaN',
        'temperature': 'NaN',
        'temperature-name': 'NaN',
        'toppingIds': [],
        'toppings': [],
        'customization': {},
        'extra-price': 0,
        'customization-price': 0,
        'cup-price': 0,
        'total-price': 0,
        'payment': '',
        'discount-type': '',
        'discount-value': 0,
        'discounted-price': 0,
        'discounted-total-price': 0,
        'discount': null
    }

    const url_rootContent_itemPage = '/root/item-page';
    const { data: data_rootContent_itemPage, isPending: isPending_rootContent_itemPage, error: error_rootContent_itemPage } = useFetch(props.cashier ? 'bypass' : url_rootContent_itemPage);

    const history = useHistory();

    const goBack = React.useCallback((input, languageInput) => { 
        if(props.cashier){
            handleClose();
        } else {
            history.goBack();
        }
    },[history]); 
    
    const {store, language, product} = useParams();

    const [paramsState, setParamsState] = useState(
        {
            store: props.cashier ? props.store : store,
            language: props.cashier ? props.language : language,
            product: props.cashier ? productItem : product
        }
    )

    //fetch size records
    const url_storeContent_cups = `/store/cups/${paramsState.store}`;
    const { data: data_storeContent_cups, isPending: isPending_storeContent_cups, error: error_storeContent_cups } = useFetch(props.cashier ? 'bypass' : url_storeContent_cups);

    const url_storeContent_product = `/store/product/${paramsState.store}`;
    const { data: data_storeContent_product, isPending: isPending_storeContent_product, error: error_storeContent_product } = useFetch(props.cashier ? 'bypass' : location.data ?  'bypass' : url_storeContent_product);

    const url_storeContent_customization = `/store/customization/${paramsState.store}`;
    const { data: data_storeContent_customization, isPending: isPending_storeContent_customization, error: error_storeContent_customization } = useFetch(props.cashier ? 'bypass' : url_storeContent_customization);

    const [url_storeContent_toppings, setUrl_storeContent_toppings] = React.useState(`/store/toppings/${paramsState.store}`);
    const { data: data_storeContent_toppings, isPending: isPending_storeContent_toppings, error: error_storeContent_toppings } = useFetch(props.cashier ? 'bypass' : url_storeContent_toppings);

    const url_storeContent_discounts = `/store/discounts/${paramsState.store}`;
    const { data: data_storeContent_discounts, isPending: isPending_storeContent_discounts, error: error_storeContent_discounts } = useFetch(props.cashier ? 'bypass' : url_storeContent_discounts);

    const [url_storeContent_settings, setUrl_storeContent_settings] = useState(`/store/settings/${paramsState.store}`);
    const { data: data_storeContent_settings, isPending: isPending_storeContent_settings, error: error_storeContent_settings } = useFetch(props.cashier ? 'bypass' : url_storeContent_settings);

    const [storeCupsData, setStoreCupsData] = useState(props.cashier ? props.cups : data_storeContent_cups);
    const [storeProductData, setStoreProductData] = useState();
    const [storeCustomizationData, setStoreCustomizationData] = useState(props.cashier ? props.customization : data_storeContent_customization);
    const [storeToppingsData, setStoreToppingsData] = useState(props.cashier ? props.toppings : data_storeContent_toppings );
    const [storeDiscountsData, setStoreDiscountsData] = useState(props.cashier ? props.discounts :data_storeContent_discounts);

    //start cashier actions
    const handleClose = React.useCallback(() => { 
        props.onClose();
    },[props]); 
    //end cashier actions

    function loadStoreProduct() {

        let productItemMemory = null;

        if(location && location.data){
            productItemMemory = location.data.item;
        } else if(!props.cashier) {
            data_storeContent_product && data_storeContent_product?.map(el => {
                
                const currentProduct = el;
                if(currentProduct.id === paramsState.product){
                    productItemMemory = currentProduct;
                }
            });
        } else {
            productItemMemory = props.productItem;
        }


        if(productItemMemory){
            item.name = productItemMemory.fields['en'];
            item.product = productItemMemory;
        };

        //if only one size
        if(storeCupsData && productItemMemory && productItemMemory.fields.cups.length === 1){
            let sizeRecordsFiltered;
            sizeRecordsFiltered = storeCupsData.filter(record => {
                return record.id === productItemMemory.fields.cups[0];
            })
            item.size = sizeRecordsFiltered[0];

            let sizePrice = productItemMemory.fields[`price-${sizeRecordsFiltered[0].fields['cup-syntax-tag']}`];
            item['cup-price'] = sizePrice;
            // if(!rendered){
                item['total-price'] = sizePrice + item['extra-price'] + item['customization-price'];
                item['total-price'] = Number.parseFloat(item['total-price']).toFixed(2);
                item['total-price'] = Number(item['total-price']);
            // }
        }

        setLoadProduct(true);
        
        return setProductItem(productItemMemory);
    };

    //read dynamic sugar
    let sugarMemory = [];
    async function loadSugar () {
        await allSugar?.map(async (el) => {
            if(productItem.fields[`sugar-${el.value}`]){
                sugarMemory.push(
                    {
                        label: rootContent[el.label] && rootContent[el.label][`${paramsState.language}`],
                        value: el.value
                    }
                )
            }
            return setSugar(sugarMemory);
        })
    }

    //read dynamic temperature
    let temperatureMemory = [];
    async function loadTemperature () {
        await allTemperatures?.map(async (el) => {
            if(
                productItem.fields[`cold-noIce`] ||
                productItem.fields[`cold-lessIce`] ||
                productItem.fields[`cold-normalIce`] ||
                productItem.fields[`cold-moreIce`] ||
                productItem.fields[`hot`]
            ){
                temperatureMemory.push(
                    {
                        label: rootContent && rootContent[el.label] && rootContent[el.label][`${paramsState.language}`],
                        value: el.value,
                        icon: rootContent[el.label] && rootContent[el.label]['attachments'][0].url,
                        icon_reverse: rootContent[el.label] && rootContent[`${el.label}-reverse`]['attachments'][0].url
                    }
                )
            }

            return setTemperature(temperatureMemory);
        })
    }

    function passColorMode(inputMode, inputPrimaryColor) {
        props.onUpdateColorMode(inputMode, inputPrimaryColor);
    }
    
    React.useEffect(() => {

        let rootContentMemory = {};
        if(!props.cashier && data_rootContent_itemPage ){
            readAirtableData(data_rootContent_itemPage, rootContentMemory);
            setRootContent(rootContentMemory);
        } else {
            setRootContent(props.rootContent);
        }

        let storeSettingsMemory = {};
        if(!props.cashier && data_storeContent_settings){
            readAirtableData(data_storeContent_settings, storeSettingsMemory);
            setStoreSettings(storeSettingsMemory);
        } else {
            setStoreSettings(props.settings);
        }

        if(props.cashier) {
            setStoreProductData(props.products);
        }

        if(!props.cashier && data_storeContent_cups){
            setStoreCupsData(data_storeContent_cups)
        }

        if(!props.cashier && data_storeContent_customization){
            setStoreCustomizationData(data_storeContent_customization)
        }

        if(!props.cashier && data_storeContent_toppings){
            setStoreToppingsData(data_storeContent_toppings)
        }

        if(!props.cashier && data_storeContent_discounts){
            setStoreDiscountsData(data_storeContent_discounts)
        }
        
        //
        //
        //read dependent variables
        
        rootContent && loadStoreProduct();

        //read sugar
        if(rootContent && productItem && productItem.fields['sugar-must-select']){
            loadSugar();
        }

        //read temperature
        if(rootContent && productItem && productItem.fields['temperature-must-select']){
            loadTemperature();
        }

        
        
        if(rootContent && sugarMemory.length !== 0 && temperatureMemory.length !== 0) {

            setMount(true)
            // update theme
            !props.cashier && data_storeContent_settings && storeSettings && passColorMode(storeSettings['dark-mode'].checked, storeSettings['primary-color']['checked'] ? storeSettings['primary-color']['text-en'] : 'bypass');

        } else {
            setMount(false)
        }

    },[
        data_rootContent_itemPage, 
        data_storeContent_cups, 
        data_storeContent_product,
        data_storeContent_customization,
        data_storeContent_toppings,
        data_storeContent_discounts,
        data_storeContent_settings,
        productItem,
        mount,
        loadProduct
    ]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    function handleUpdateSugar(input){
        itemMemory.sugar = input;
        itemMemory['sugar-name'] = rootContent[`${itemMemory.sugar}-sugar`].en;

        setItem(prevState => ({
            ...prevState,
            'sugar': itemMemory.sugar,
            'sugar-name': itemMemory['sugar-name']
        }))
    }

    function handleUpdateTemperature(input){
        itemMemory.temperature = input;
        itemMemory['temperature-name'] = rootContent[`${itemMemory.temperature}`].en;

        setItem(prevState => ({
            ...prevState,
            'temperature': itemMemory.temperature,
            'temperature-name': itemMemory['temperature-name']
        }))
    }

    function selectCustomization(record, action) {

        let selectedRecord = record;
        let selectedAction = action;

        itemMemory['customization-price'] =  Number(selectedRecord.fields.price);
        if(selectedAction === 'plus') {
            itemMemory['customization'] = selectedRecord.fields;
            itemMemory['total-price'] = item['cup-price'] + item['extra-price'] + itemMemory['customization-price']
        } else if (selectedAction === 'minus') {
            itemMemory['customization'] = '';
            itemMemory['total-price'] = item['cup-price'] + item['extra-price'] - itemMemory['customization-price']
        }
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        setItem(prevState => ({
            ...prevState,
            'customization-price': itemMemory['customization-price'],
            'customization': itemMemory['customization'],
            'total-price': Number(itemMemory['total-price']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
    }

    function handleSelectSize(sizeRecord){
        itemMemory.size = sizeRecord;
        itemMemory['cup-price'] = productItem.fields[`price-${sizeRecord.fields['cup-syntax-tag']}`];
        itemMemory['total-price'] = itemMemory['cup-price'] + item['extra-price'] + item['customization-price'];
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);

        setItem(prevState => ({
            ...prevState,
            'size': itemMemory.size,
            'cup-price': itemMemory['cup-price'],
            'total-price': Number(itemMemory['total-price']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
    }

    function selectDiscount(record, action){
        if(action === 'plus') {
            if(record.fields['direct-discount']){
                itemMemory['discount-type'] =  'direct';
                itemMemory['discount-value'] =  Number(record.fields['direct-discount']);
            } else if (record.fields['percentage-off-discount']) {
                itemMemory['discount-type'] =  'percentage';
                itemMemory['discount-value'] =  Number(record.fields['percentage-off-discount']);
            }
            itemMemory['discount'] =  record.fields;
        } else if (action === 'minus') {
            itemMemory['discount-type'] = '';
            itemMemory['discount-value'] = 0;
            itemMemory['discounted-price'] = 0;
            itemMemory['discounted-total-price'] = 0;
            itemMemory['discount'] = null;
        }
        
        //validate discounted price
        if(itemMemory['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(itemMemory['discount-value']);
            itemMemory['discounted-total-price'] = Number(item['total-price']) + itemMemory['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (itemMemory['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(item['total-price']) * Number(itemMemory['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(item['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        }

        setItem(prevState => ({
            ...prevState,
            'discount-type': itemMemory['discount-type'],
            'discount-value': Number(itemMemory['discount-value']),
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price']),
            'discount': itemMemory['discount']
        }))
    }

    function handleUpdateExtra(action, record){

        let actionMemory = action;
        let recordMemory = record;

        let currToppings = [...item.toppings];
        let currToppingIds = [...item.toppingIds];

        if(actionMemory === 'minus') {
            const removeIndex = currToppingIds.indexOf(recordMemory.id);
            currToppings.splice(removeIndex, 1);
            currToppingIds.splice(removeIndex, 1);
            itemMemory['extra-price'] = item['extra-price'] - recordMemory.fields.price;
        } else if (actionMemory === 'plus') {
            currToppingIds.push(recordMemory.id);
            currToppings.push(recordMemory);
            itemMemory['extra-price'] = item['extra-price'] + recordMemory.fields.price;
        }
        itemMemory['total-price'] = item['cup-price'] + itemMemory['extra-price'] + item['customization-price'];
        itemMemory['total-price'] = Number.parseFloat(itemMemory['total-price']).toFixed(2);
        itemMemory['total-price'] = Number(itemMemory['total-price']);
        // itemMemory['total-price'] = Number(itemMemory['total-price']);
        // toppingIndexes = currToppingIndexes;
        // item.toppings = currToppings;

        if(item['discount-type'] === 'direct'){
            itemMemory['discounted-price'] = 0 - Number(item['discount-value']);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) + item['discount-value'];
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else if (item['discount-type'] === 'percentage') {
            itemMemory['discounted-price'] = ( Number(itemMemory['total-price']) * Number(item['discount-value']) ) / 100;
            itemMemory['discounted-price'] = Number.parseFloat(itemMemory['discounted-price']).toFixed(2);
            itemMemory['discounted-total-price'] = Number(itemMemory['total-price']) - Number(itemMemory['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(itemMemory['discounted-total-price']).toFixed(2);
        } else {
            itemMemory['discounted-price'] = Number(item['discounted-price']);
            itemMemory['discounted-total-price'] = Number.parseFloat(item['discounted-total-price']).toFixed(2);
        }
        let price = Number(itemMemory['total-price']);
        // item['total-price'] = price;
        setItem(prevState => ({
            ...prevState,
            'total-price': price,
            'toppings': currToppings,
            'toppingIds': currToppingIds,
            'extra-price': itemMemory['extra-price'],
            // 'total-price': Number(itemMemory['total-price']),
            'total-price': price,
            'discounted-price': Number(itemMemory['discounted-price']),
            'discounted-total-price': Number(itemMemory['discounted-total-price'])
        }))
        // item['total-price'] = price;
    }

    function handleAddToCart(itemInput) {
        if(itemInput.size === null){
            //enter sugar dialog
            setAlert(true);
            setAlertMessage(`${rootContent['cart-alert-size'][`${paramsState.language}`]}`)

        } else if(productItem && productItem.fields && productItem.fields['sugar-must-select'] && itemInput['sugar-name'] === 'NaN'){
            //enter temperature dialog
            setAlert(true);
            setAlertMessage(`${rootContent['cart-alert-sugar'][`${paramsState.language}`]}`)

        } else if(productItem && productItem.fields && productItem.fields['temperature-must-select'] && itemInput['temperature-name'] === 'NaN'){
            //enter size dialog
            setAlert(true);
            setAlertMessage(`${rootContent['cart-alert-temperature'][`${paramsState.language}`]}`)

        } else {
            props.onUpdateCart(itemInput);
            if(props.cashier){
                handleClose();
            } else {
                history.push(`/main/${paramsState.store}/${paramsState.language}`);
            }
        }
    }

    return (
        <ContainerStack
            spacing={8}
            sx={{
                backgroundColor: theme.palette.background.paper,
            }}
        >
            {   props.client &&
                <ImageHolder>
                    { 
                        isPending_storeContent_product && !location.data && !productItem ?
                        <Skeleton sx={{ height: '100vh' }} animation="wave" variant="rectangular" />
                        :
                        productItem ?
                        <ImageSlides 
                            images={productItem.fields.images} 
                            alt='product-images' 
                            height={ xl ? '480px' : md ? '420px' : xs ? '364px' : '364px' }
                            sx={{
                                width: '100%'
                            }}
                            hideThumbs={true} 
                        />
                        :
                        <Skeleton sx={{ height: '100vh' }} animation="wave" variant="rectangular" />
                    }
                </ImageHolder>
            }

            <BackButtonDiv>
                {   rootContent && rootContent['back-to-home'] && paramsState.language &&
                    <ScFab 
                        variant="extended" 
                        color="primary" 
                        aria-label={rootContent['back-to-home'][`${paramsState.language}`]} 
                        onClick={() => goBack()}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size='lg' />
                        <Typography variant='body1' color={theme.palette.text.on_surface_reverse}>
                            {rootContent['back-to-home'][`${paramsState.language}`]}
                        </Typography>
                    </ScFab>
                }
            </BackButtonDiv>

            <MainStack
                cashier={props.cashier ? "true" : "false"}
                spacing={4}
                sx={{
                    top: {
                        xs: `${ props.cashier ? '0' : '364px'}`,
                        md: `${ props.cashier ? '0' : '420px'}`,
                        xl: `${ props.cashier ? '0' : '480px'}`
                    },
                    paddingBottom: `${ props.cashier ? '64px' : '0'}`,
                    paddingTop: `${ props.cashier ? '48px' : '0'}`,
                    maxWidth: '100%',
                }}
            >
                {   !props.cashier &&
                    <DragLineHolder>
                        <FontAwesomeIcon icon={faMinus} size='2x' color={theme.palette.text.on_surface_variant} />
                    </DragLineHolder>
                }

                {/* title */}
                <Stack>
                    <Typography variant="caption" color={theme.palette.text.on_surface}>
                        {   rootContent &&
                            rootContent['product-label'][`${paramsState.language}`]
                        }
                    </Typography>
                    <Typography variant="h4_bold" color={theme.palette.text.on_surface}>
                        {   productItem &&
                            productItem.fields[`${paramsState.language}`]
                        }
                    </Typography>
                </Stack>

                {/* description */}
                <Stack>
                    <Typography variant="caption" color={theme.palette.text.on_surface}>
                        {   rootContent &&
                            rootContent['product-description'][`${paramsState.language}`]
                        }
                    </Typography>
                    <Typography variant="body1" color={theme.palette.text.on_surface}>
                        {   productItem &&
                            productItem.fields[`${paramsState.language}-note`]
                        }
                    </Typography>
                </Stack>

                {/* size */}
                {   productItem && rootContent && data_storeContent_cups && storeCupsData &&
                    <Size content={rootContent} preSelect={ productItem.fields.cups.length === 1 ? true : false} sizeRecords={storeCupsData} store={paramsState.store} language={paramsState.language} product={productItem} onSelectSize={(input) => handleSelectSize(input)}/>
                }
                {/* sugar */}
                {   productItem && rootContent && productItem.fields && productItem.fields['sugar-must-select'] && paramsState.language && rootContent['helper-default-sugar'] &&
                    <Stack
                        spacing={2}
                    >
                        <Typography variant="body2_bold" color={theme.palette.text.on_surface}>
                                {rootContent.sugar[`${paramsState.language}`]}
                        </Typography>
                        <ButtonsGroupBox>
                            <SimpleButtonsGroup
                                content={rootContent} 
                                language={paramsState.language} 
                                product={productItem.fields} 
                                helperText={rootContent && rootContent['helper-default-sugar'][`${paramsState.language}`]} 
                                buttons={sugar}
                                onClick={(input) => handleUpdateSugar(input)} 
                            />
                        </ButtonsGroupBox>
                    </Stack>
                }
                {/* temperature */}
                {   productItem && rootContent && productItem.fields && rootContent.temperature && productItem.fields && productItem.fields['temperature-must-select'] && paramsState.language && rootContent['helper-default-temperature'] &&
                    <Stack
                        spacing={2}
                    >
                        <Typography variant="body2_bold" color={theme.palette.text.on_surface}>
                                {rootContent.temperature[`${paramsState.language}`]}
                        </Typography>
                        <ButtonsGroupBox>
                            <IconButtonsGroup
                                content={rootContent} 
                                language={paramsState.language} 
                                product={productItem.fields} 
                                helperText={rootContent['helper-default-temperature'][`${paramsState.language}`]} 
                                buttons={temperature}
                                onClick={(input) => handleUpdateTemperature(input)} 
                            />
                        </ButtonsGroupBox>
                    </Stack>
                }
                {/* customization */}
                {   productItem && rootContent && productItem.fields['extra-customization'] && storeCustomizationData &&
                    <CustomizationSection
                        content={rootContent}
                        store={paramsState.store} 
                        language={paramsState.language} 
                        product={productItem}
                        customizationRecords={storeCustomizationData}
                        onClick={(record, action) => selectCustomization(record, action)} 
                    /> 
                }
                {/* toppings */}
                {   productItem && rootContent && productItem.fields['activate-toppings'] && paramsState.language && paramsState.store && storeToppingsData &&
                    <ExtraSection 
                        content={rootContent} 
                        store={paramsState.store} 
                        language={paramsState.language} 
                        product={productItem}
                        allRecords={storeToppingsData}
                        // currRecords={item.toppings}
                        onUpdateExtra={(action, record) => handleUpdateExtra(action, record)}
                    />
                }
                {/* discounts */}
                {   productItem && rootContent && productItem.fields && productItem.fields[`discounts-activated-${props.cashier ? 'cashier' : 'client'}`] && productItem.fields[`discounts-activated-${props.cashier ? 'cashier' : 'client'}`].indexOf(true) !== -1 && storeDiscountsData &&
                    <DiscountSection
                        cashier={props.cashier ? true : false}
                        content={rootContent}
                        store={paramsState.store} 
                        language={paramsState.language} 
                        product={productItem}
                        records={storeDiscountsData}
                        onClick={(record, action) => selectDiscount(record, action)} 
                    /> 
                }
                {   rootContent && paramsState.language && productItem && storeSettings && storeSettings['store-open'] && storeSettings['store-open'].checked ?
                    <Button 
                        sx={{ 
                            position: props.cashier ? 'static' : 'sticky', 
                            bottom: '32px', 
                            maxWidth: '100%', 
                            zIndex: '999',
                            top: 0,
                        }} 
                        fullWidth={true} 
                        variant="contained" 
                        size='large' 
                        onClick={() => handleAddToCart(item)}
                    >
                        <Typography>
                            {   rootContent && rootContent['CTA-add'] &&
                                rootContent['CTA-add'][`${paramsState.language}`]
                            } {   rootContent && rootContent['CTA-add'] &&
                                rootContent['CTA-to-cart'][`${paramsState.language}`]
                            }
                        </Typography>
                    </Button>
                    :
                    <Button fullWidth={true} disabled variant="contained" size='large'>
                        <Typography>
                            {   rootContent && rootContent['CTA-add'] &&
                                rootContent['CTA-add'][`${paramsState.language}`]
                            } {   rootContent && rootContent['CTA-add'] &&
                                rootContent['CTA-to-cart'][`${paramsState.language}`]
                            }
                        </Typography>
                    </Button>
                }
                {   alert && alertMessage &&
                    <Alert severity="info">{`${alertMessage}`}</Alert>
                }
                {   !props.cashier &&
                    <Footer />
                }
            </MainStack>

        </ContainerStack>
    )
}

export default ProductPage;