import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import { device } from './device';
import {useHistory} from 'react-router-dom';
import { theme_dark } from './theme_dark';
import { useTheme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Sc_Card = styled(Card)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    justifyContent: 'flex-start',
    minHeight: '100%',
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row'
    }
}));

const Sc_CardMedia = styled(CardMedia)(({theme}) => ({
    borderRadius: 10,
    height: 120,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        maxWidth: 120,
    }
}));

const Sc_CardContent = styled(CardContent)(({theme}) => ({
    margin: '0px 8px',
}));

const SC_Card = styled(Card)(({theme}) => ({
    borderRadius: 10,
    margin: '4px 0',
    [theme.breakpoints.up('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 720,
    }
}));

const LabelBox = styled(Box)(({theme}) => ({
    textAlign: 'left',
}));

const OverlayBox = styled(Box)(({theme}) => ({
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    zIndex: 99,
}));


function ProductItem(props) {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.up("xs"));
    const sm = useMediaQuery(theme.breakpoints.up("sm"));
    const md = useMediaQuery(theme.breakpoints.up("md"));
    const lg = useMediaQuery(theme.breakpoints.up("lg"));
    const xl = useMediaQuery(theme.breakpoints.up("xl"));

    const item = props.item.fields;
    const history = useHistory();

    const handleSelectItem = React.useCallback((input, languageInput) => { 

        {   props.cashier ?
            props.onCashierCallItem(input)
            :
            history.push({
                pathname: `/product/${props.store}/${languageInput}/${input}`,
                data: {
                    item: props.item
                }
            })
        }
    
    },[history]); 

    function renderCard(){
        return (
            <Sc_Card elevation={5}>
                {   item['images'] && !props.simpleLayout &&
                    <Sc_CardMedia
                    component="img"
                    image={item['images'][0].thumbnails.large.url}
                    alt="item image"
                    />
                }
                <Sc_CardContent>
                    <Typography gutterBottom variant="h5"
                        color={theme.palette.text.on_surface}
                    >
                        {
                            item[`${props.language}`]
                        }
                    </Typography>
                    {   item['cup-syntax-tag'] &&
                        item['cup-syntax-tag']?.map((el, index) => {
                            return(
                                <LabelBox key={index}>
                                    {   item[`size-${el}`] && item[`price-${el}`] &&
                                        <Typography variant="body2" color={theme.palette.text.on_surface_variant}>
                                            {item[`cups-${props.language}`][index]} {item[`price-${el}`]} €
                                        </Typography>
                                    }
                                </LabelBox>
                            )
                        })
                    }
                </Sc_CardContent>
            </Sc_Card>
        )
    }

    return (
        <SC_Card 
            raised={true}
            sx={{ 
                maxWidth: 1440, 
                display: 'flex', 
                flexDirection: 'row', 
                flexGrow: 1,
                borderRadius: '10px' 
            }}
        >
            {
                item && !item['sold-out'] ?
                <CardActionArea onClick={() => {handleSelectItem(props.item.id, props.language)}}>
                    {renderCard()}
                </CardActionArea>
                :
                <Box sx={{ alignItems: 'flex-start', display: 'flex', position: 'relative', width: '100%', height: '100%' }} >
                    {renderCard()}
                    <OverlayBox>
                        <Box>
                            <Typography variant="body1" color={theme.palette.text.on_surface_reverse}>
                                {   props.rootContent['sold-out'] &&
                                    props.rootContent['sold-out'][`${props.language}`]
                                }
                            </Typography>
                        </Box>
                    </OverlayBox>
                </Box>
            }
        </SC_Card>
    );
}

export default ProductItem;